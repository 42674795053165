import React from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { FaPython } from "react-icons/fa";
import { SiTerraform } from "react-icons/si";
import { SiPowershell } from "react-icons/si";
import { FaAws } from "react-icons/fa";
import { SiMicrosoftazure } from "react-icons/si";
import { SiJavascript } from "react-icons/si";
import "./WhatIDo.css";

function WhatIDo() {
  return (
    <Container>
      <Col className="whatido__header">
        <h1>Technologies</h1>

        <Row>
          {/* <Col> */}
          <Card>
            <Card.Header as="h4">Cloud</Card.Header>
            <Card.Body>
              <FaAws style={{ fontSize: "80px" }} />
              <SiMicrosoftazure
                style={{ fontSize: "80px", marginLeft: "50px" }}
              />
            </Card.Body>
          </Card>
          <Card>
            <Card.Header as="h4">Langauges</Card.Header>
            <Card.Body>
              <FaPython style={{ fontSize: "80px" }} />
              <SiTerraform style={{ fontSize: "80px" }} />
              <SiPowershell style={{ fontSize: "80px" }} />
              <SiJavascript style={{ fontSize: "80px" }} />
            </Card.Body>
          </Card>
          {/* </Col> */}
        </Row>
      </Col>
    </Container>
  );
}

export default WhatIDo;
