import React from "react";
import { Link } from "react-router-dom";
import { Container, Nav, Navbar } from "react-bootstrap";
import { AiFillGithub } from "react-icons/ai";
import { AiFillLinkedin } from "react-icons/ai";
import { AiOutlineMail } from "react-icons/ai";
import "./Header.css";

function Header() {
  return (
    <div className="header">
      <Navbar collapseOnSelect sticky="top">
        <Container>
          <Navbar.Brand href="/"></Navbar.Brand>
          <Nav className="justify-content-center" style={{ flex: 1 }}>
            <Nav.Link href="https://github.com/james5101/" target="__blank">
              <AiFillGithub style={{ fontSize: "45px" }} />
            </Nav.Link>
            <Nav.Link
              href="https://www.linkedin.com/in/james-noonan-57800270/"
              target="__blank"
            >
              <AiFillLinkedin style={{ fontSize: "45px" }} />
            </Nav.Link>
            <Nav.Link href="mailto:james.c.noonan@gmail.com" target="__blank">
              <AiOutlineMail style={{ fontSize: "45px" }} />
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;
