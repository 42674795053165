import React from "react";
import { Col, Container, Nav, Row } from "react-bootstrap";
import { AiFillGithub } from "react-icons/ai";
import { AiFillLinkedin } from "react-icons/ai";
import { AiOutlineMail } from "react-icons/ai";
import Header from "../Header/Header";
import "./Footer.css";

function Footer() {
  return (
    <Container className="footer" fluid>
      <h1>Contact</h1>
      <Container>
        <Header />
      </Container>
    </Container>
  );
}

export default Footer;
