import React from "react";
import {
  Container,
  Card,
  Button,
  Col,
  Row,
  Jumbotron,
  NavLink,
  Navbar,
  Nav,
} from "react-bootstrap";
import MyResume from "../../files/JamesNoonanResume.pdf";
import "./Home.css";

function Home() {
  return (
    <div className="home">
      <Container>
        {/* <Jumbotron>
          <h1>James Noonan</h1>
          <h2>DevOps Guy</h2>
          <p>
            Dedicated to helping companies build cross functional teams and
            automate everything.
          </p>
          <p>
            <a href={MyResume} download="JamesNoonanResume" target="_blank">
              <Button>Download Resume</Button>
            </a>
          </p>
        </Jumbotron> */}
        {/* <Card className="text-left">
          <Card.Body>
            <Card.Title>James Noonan</Card.Title>
            <Card.Text>DevOps Engineer</Card.Text>
          </Card.Body>
        </Card> */}
        <Col>
          <Row>
            <Col className="home__aboutme">
              <h1>James Noonan</h1>
              <h2>DevOps Guy</h2>
              <p>
                Dedicated to helping companies build cross functional teams and
                automate everything.
              </p>

              <a href={MyResume} download="JamesNoonanResume" target="_blank">
                <Button>Download Resume</Button>
              </a>
            </Col>
            {/* <Col className="home__img">
              <img src={picture} alt="Homepage" />
            </Col> */}
          </Row>
        </Col>
      </Container>
    </div>
  );
}

export default Home;
