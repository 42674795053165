import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/Header/Header";
import Home from "./components/Home/Home";
import WhatIDo from "./components/WhatIDo/WhatIDo";
import Experience from "./components/Experience/Experience";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <div className="App">
      <div className="bg">
        <Header />
        <Home />
      </div>
      <WhatIDo />
      <Experience />
      <Footer />
    </div>
  );
}

export default App;
