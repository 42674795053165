import React from "react";
import { Container, Col, Card, CardGroup, Nav } from "react-bootstrap";
import ContinoImg from "../../images/contino.png";
import OneCallImg from "../../images/onecall.png";
import "./Experience.css";

function Experience() {
  return (
    <Container className="experience" fluid>
      <Col>
        <h1>Experience</h1>

        <CardGroup className>
          <Col className="col-sm">
            <Card style={{ width: "30rem", padding: 0 }}>
              <Card.Header as="h1">Contino</Card.Header>

              <Card.Body>
                <Nav.Link href="https://contino.io" target="__blank">
                  <Card.Img
                    className="bg-white rounded-circle mb-3 img-center img-fluid shadow-lg "
                    variant="top"
                    src={ContinoImg}
                  ></Card.Img>
                </Nav.Link>

                <Card.Title>Senior Devops Consultant</Card.Title>
                <Card.Subtitle>June 2019 - Present</Card.Subtitle>
                <Card.Text>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col className="col-sm">
            <Card style={{ width: "30rem" }}>
              <Card.Header as="h1">One Call</Card.Header>
              <Card.Body>
                <Nav.Link href="https://onecallcm.com/" target="__blank">
                  <Card.Img
                    className="bg-white rounded-circle mb-3 img-center img-fluid shadow-lg "
                    variant="top"
                    src={OneCallImg}
                  ></Card.Img>
                </Nav.Link>
                <Card.Title>IT Architect - DevOps</Card.Title>
                <Card.Subtitle>May 2017 - May 2019</Card.Subtitle>
                <Card.Text>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col className="col-sm">
            <Card style={{ width: "30rem" }}>
              <Card.Header as="h1">One Call</Card.Header>

              <Card.Body>
                <Nav.Link href="https://onecallcm.com/" target="__blank">
                  <Card.Img
                    className="bg-white rounded-circle mb-3 img-center img-fluid shadow-lg "
                    variant="top"
                    src={OneCallImg}
                  ></Card.Img>
                </Nav.Link>
                <Card.Title>Release Engineer</Card.Title>
                <Card.Subtitle>October 2016 - May 2017</Card.Subtitle>
                <Card.Text>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </CardGroup>
      </Col>
    </Container>
  );
}

export default Experience;
